import React, { Fragment } from 'react'
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube, FaLock } from 'react-icons/fa';
import './footer.scss';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
    <div className="footer__left">
      <a href="https://www.facebook.com/AllGrowSA" target="_blank" rel="noopener noreferrer">
        <FaFacebook className="footer__icon" />
      </a>
      <a href="https://www.instagram.com/allegrowsa/" target="_blank" rel="noopener noreferrer">
        <FaInstagram className="footer__icon" />
      </a>
      <a href="https://whatsapp.com/channel/0029VaZ5QaN2phHGZcmyOA2p" target="_blank" rel="noopener noreferrer">
        <FaWhatsapp className="footer__icon" />
      </a>
      <a href="https://www.youtube.com/@AlleGrowSA" target="_blank" rel="noopener noreferrer">
        <FaYoutube className="footer__icon" />
      </a>
    </div>
    <div className="footer__middle">
            Food Allergy Support App | <Link to="/disclaimer" className="disclaimer-link">Disclaimer</Link>
        </div>
    <div className="footer__right">
      Powered by Drivatic
            <a href="https://allegrow.co.za/Login" target="_blank" rel="noopener noreferrer">
        <FaLock className="footer__lock-icon" />
      </a>
    </div>
  </footer>
  );
};
  
  export default Footer;
